import React from "react";
function getLinkByFileType(fileType) {
  switch (fileType) {
    case "docx":
      return "https://www.logo.wine/a/logo/Microsoft_Word/Microsoft_Word-Logo.wine.svg";
    case "pdf":
      return "https://logowik.com/content/uploads/images/adobe-pdf3324.jpg";
    case "ppt":
      return "https://thuvienhuongdan.com/contents/upload/images/jean.k/highlight/2018_06_19-1529425502-power-point.png";
    case "pptx":
      return "https://thuvienhuongdan.com/contents/upload/images/jean.k/highlight/2018_06_19-1529425502-power-point.png";
    default:
      return "Invalid file type";
  }
}
function capitalizeFirstLetter(str) {
  // Kiểm tra nếu chuỗi đầu vào là rỗng hoặc null
  if (!str) {
    return "";
  }

  // Chuyển chữ cái đầu của chuỗi thành chữ cái viết hoa và ghép nối với phần còn lại của chuỗi
  return str.charAt(0).toUpperCase() + str.slice(1);
}
const CardDocs = ({ title_file, type, block, download, id }) => {
  const fileLink = getLinkByFileType(type);
  return (
    <div className="bg-slate-300 p-2 rounded-md h-[320px] relative flex flex-col cursor-pointer">
      <div className="h-[200px] p-2">
        <img
          src={fileLink}
          className="block w-full h-full object-cover"
          alt=""
        />
      </div>
      <div className="flex-1 relative">
        <h1 className="font-[600] text-base line-clamp-2 text-justify leading-relaxed">
          {capitalizeFirstLetter(title_file)}
        </h1>
        <button className="bg-blue-300 text-white font-bold text-lg capitalize w-full h-[40px] rounded-md absolute bottom-0">
          <a href={download}>Download</a>
        </button>
      </div>
    </div>
  );
};

export default CardDocs;
// word: https://play-lh.googleusercontent.com/WKCyWYVxUKpFS5JgXOKk3OHY6PZpLN_L8c-8fJhyHMwY7P_eN_sa66-wcMbRFil-EA
// pdf: https://s.cafebazaar.ir/images/icons/com.pdf.reader.android.pdfviewer.pdfeditor-7e0f9b28-c9cc-47a5-95ec-611c1a402159_512x512.webp
// ppt: https://thuvienhuongdan.com/contents/upload/images/jean.k/highlight/2018_06_19-1529425502-power-point.png
