import { useForm } from "react-hook-form";
import HeaderNav from "../components/HeaderNav";
import TagNamePage from "../components/card/TagNamePage";
import Label from "../components/label/Label";
import UploadDoc from "../modules/UploadDoc";
import Input from "../components/input/Input";
import { useDispatch, useSelector } from "react-redux";
import { auth, db, storage } from "../store/firebaseconfig";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { toast } from "react-toastify";
import { setNameFile } from "../slice/GlobalSlice";
function encodeCurrentDateTime() {
  const currentDateTime = new Date();
  const formattedDateTime = `${(currentDateTime.getMonth() + 1)
    .toString()
    .padStart(2, "0")}${currentDateTime
    .getDate()
    .toString()
    .padStart(2, "0")}${currentDateTime.getFullYear()}${currentDateTime
    .getHours()
    .toString()
    .padStart(2, "0")}${currentDateTime
    .getMinutes()
    .toString()
    .padStart(2, "0")}${currentDateTime
    .getSeconds()
    .toString()
    .padStart(2, "0")}${currentDateTime
    .getMilliseconds()
    .toString()
    .padStart(3, "0")}`;
  return parseInt(formattedDateTime);
}
const AddDocumentFile = () => {
  const { file } = useSelector((state) => state.global);
  const {
    control,
    handleSubmit,
    register,
    reset,
    formState: { isSubmitting },
  } = useForm();
  const dispatch = useDispatch();
  const onSubmit = async (e) => {
    const { namefile } = e;
    const fileName = file.name;
    const fileExtension = fileName.slice(
      ((fileName.lastIndexOf(".") - 1) >>> 0) + 2
    );
    const newName = encodeCurrentDateTime() + "." + fileExtension;
    const select_type_file = fileExtension.toLowerCase();
    const storageRef = ref(storage, `/files/${newName}`);
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {},
      async (error) => {
        toast.error("Upload file failed");
      },
      async () => {
        try {
          const url = await getDownloadURL(uploadTask.snapshot.ref);
          const docRef = await addDoc(collection(db, "multiplefile"), {
            UrlDownload: url,
            timestamp: serverTimestamp(),
            userID: auth.currentUser.uid,
            select_type_file,
            ...e,
            namefile: namefile.toLowerCase().trimEnd(),
            author: auth.currentUser.displayName,
          });
          await updateDoc(doc(db, "multiplefile", docRef.id), {
            docId: docRef.id,
          });
          toast.success("Submit is successfully");
          reset({
            namefile: "",
            select_category_file: "",
          });
          dispatch(setNameFile(""));
        } catch (error) {
          toast.error("Submit failed");
        }
      }
    );
  };
  return (
    <div className="border-black dark:bg-slate-500">
      <HeaderNav></HeaderNav>
      <div className="h-full sm:mb-5 bg-slate-300 sm:px-2 sm:py-5 lg:mx-5 lg:px-5 lg:rounded-md">
        <TagNamePage
          icon={<i className="fa-solid fa-folder-plus"></i>}
          title="Thêm tài liệu"
          desc="Trang quản trị tài liệu"
        ></TagNamePage>
        <form
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
          className="sm:flex sm:flex-col sm:gap-y-5 lg:grid lg:grid-cols-2 lg:gap-10"
        >
          <div className="flex flex-col gap-y-2">
            <Label htmlFor="namefile">Name File</Label>
            <Input
              required
              control={control}
              placeholder="Enter your name file..."
              type="text"
              id="namefile"
              name="namefile"
            ></Input>
          </div>
          <div className="flex flex-col gap-y-2">
            <Label htmlFor="select_category_file">Chọn chủ đề theo khối</Label>
            <select
              required
              defaultValue={""}
              {...register("select_category_file")}
              name="select_category_file"
              id="select_category_file"
              className="h-[40px] px-5 rounded-md"
            >
              <option value="" disabled>
                Chọn chủ đề theo khối
              </option>
              <option value="11">Khối 11</option>
              <option value="10">Khối 10</option>
              <option value="8">Khối 8</option>
              <option value="7">Khối 7</option>
              <option value="6">Khối 6</option>
              <option value="5">Khối 5</option>
              <option value="4">Khối 3</option>
              <option value="3">Khối 4</option>
              <option value="2">Khối 2</option>
              <option value="1">Khối 1</option>
            </select>
          </div>

          <div className="flex flex-col gap-y-10">
            <UploadDoc control={control} register={register}></UploadDoc>
          </div>
          <button
            disabled={isSubmitting}
            className={`bg-blue-500 col-span-2 mx-auto text-white font-bold rounded-md text-lg h-[40px] w-[150px] ${
              isSubmitting ? "opacity-50 cursor-not-allowed bg-gray-300" : ""
            }`}
            type="submit"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddDocumentFile;
