import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFile, setNameFile } from "../slice/GlobalSlice";
import Label from "../components/label/Label";

const UploadDoc = ({ register, ...props }) => {
  const { nameFile } = useSelector((state) => state.global);
  const dispatch = useDispatch();
  const handleChange = (e) => {
    const selected_file = e.target.files[0];
    dispatch(setNameFile(selected_file.name));
    dispatch(setFile(selected_file));
  };
  return (
    <div className="flex flex-col gap-y-2">
      <Label htmlFor="doc-file">Chọn file tài liệu</Label>
      <div className="relative w-full bg-blue-400 rounded-md h-[40px] flex items-center justify-center">
        <input
          onChange={handleChange}
          {...props}
          type="file"
          name="doc-file"
          id="doc-file"
          className="border-2 w-full h-full absolute opacity-0 z-50"
        />
        <h1 className="absolute w-full text-center font-bold text-base text-white z-30 line-clamp-1">
          {`${nameFile === "" ? "Upload Tài Liệu" : nameFile}`}
        </h1>
      </div>
    </div>
  );
};

export default UploadDoc;
