import React from "react";
function getCurrentDate() {
  const currentDate = new Date();
  const day = currentDate.getDate();
  const month = currentDate.getMonth() + 1; // Tháng bắt đầu từ 0, cộng thêm 1
  const year = currentDate.getFullYear();
  const formattedDate = `${day < 10 ? "0" : ""}${day}-${
    month < 10 ? "0" : ""
  }${month}-${year}`;

  return formattedDate;
}
const currentDate = getCurrentDate();

const CardMusic = ({ url_path, onClick, title, author }) => {
  return (
    <div
      onClick={onClick}
      className="flex bg-slate-300 p-1 rounded-md gap-x-2 cursor-pointer group select-none relative"
    >
      <div className="w-[75px] h-[75px] rounded-md overflow-hidden z-45">
        <img
          className="w-full h-full object-cover"
          src="https://thuamviet.com/photos/BLOG-TUYEN%20DUNG/CAM%20NANG/CN%20tong%20hop/%C3%82m%20nh%E1%BA%A1c%20truy%E1%BB%81n%20th%E1%BB%91ng%20Vi%E1%BB%87t%20nam%20x%C6%B0a%20v%C3%A0%20nay/am%20nhac%20truyen%20thong%20xua%20va%20nay.jpg"
          alt=""
        />
      </div>
      <div className="flex flex-col z-45">
        <h1 className="title font-bold text-base line-clamp-1 z-45">{title}</h1>
        <h1 className="text-base text-gray-700 font-[500] z-45 capitalize">
          {author}
        </h1>
        <h1 className="text-sm text-gray-700 font-[500] z-45">
          Ngày cập nhật: {currentDate}
        </h1>
      </div>
      <div className="absolute bg-black top-0 w-full h-full left-0 opacity-0">
        {url_path}
      </div>
    </div>
  );
};

export default CardMusic;
