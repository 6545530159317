import React from "react";
import Button from "../components/button/Button";
import { auth } from "../store/firebaseconfig";
import { signOut } from "@firebase/auth";
import Swal from "sweetalert2";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setIsLogin } from "../slice/LoginSlice";
import { setSideBarShow } from "../slice/SideBarToggleSlice";
const sideBar_list = [
  {
    name: "Trang chủ",
    icon: <i className="fa-solid fa-house"></i>,
  },
  {
    name: "Tìm kiếm",
    icon: <i className="fa-solid fa-magnifying-glass"></i>,
  },
  {
    name: "friend",
    icon: <i className="fa-solid fa-user-group"></i>,
  },
  {
    name: "Tài liệu",
    icon: <i className="fa-solid fa-file-word"></i>,
  },
  {
    name: "account",
    icon: <i className="fa-regular fa-circle-user"></i>,
  },
  {
    name: "Quản lí",
    icon: <i className="fa-solid fa-database"></i>,
  },
  {
    name: "Hỗ trợ",
    icon: <i className="fa-solid fa-headset"></i>,
  },
  {
    name: "Đăng xuất",
    icon: <i className="fa-solid fa-right-from-bracket"></i>,
  },
  {
    name: "Trò chơi",
    icon: <i className="fa-solid fa-gamepad"></i>,
  },
  {
    name: "Âm nhạc",
    icon: <i className="fa-solid fa-music"></i>,
  },
  {
    name: "Thêm tài liệu",
    icon: <i className="fa-solid fa-folder-plus"></i>,
  },
];
const SideBar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleLogout = () => {
    Swal.fire({
      title: "Đăng xuất",
      text: "Xác nhận đăng xuất",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Đăng xuất",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Đăng xuất!", "Đăng xuất thành công", "success");
        dispatch(setIsLogin(false));
        dispatch(setSideBarShow(false));
        localStorage.setItem("isLogin", false);
        navigate("/");
        try {
          signOut(auth);
        } catch (error) {
          console.log(error);
        }
      }
    });
  };
  const { isShow } = useSelector((state) => state.ShowSideBarSlice);
  const { isLogin } = useSelector((state) => state.Login);
  return (
    <div
      className={`dark:bg-slate-800 ${
        isShow === true
          ? "sm:translate-x-[0%] xl:translate-x-[0%]"
          : "sm:translate-x-[-100%] xl:translate-x-[0%]"
      }  dark:border-white dark:text-white sm:absolute  
      sm:w-[100%] sm:bottom-0 sm:top-0 sm:left-0 sm:right-0 transition-all z-[100] overflow-auto bg-slate-300 select-none lg:sticky  lg:bottom-0 lg:left-0 h-[100vh] lg:border-r border-black`}
    >
      {/* logo */}
      <div className="flex justify-between items-center px-3 sm:py-2">
        <div className="sm:text-3xl lg:text-2xl uppercase font-bold flex items-center justify-center w-full">
          <NavLink
            to="/"
            onClick={() => dispatch(setSideBarShow(false))}
            className="text-gradient bg-gradient-to-r from-blue-500 via-purple-500 mx-auto block to-red-500 transition-all line-clamp-1 align-cente"
          >
            GDĐP - BRVT
          </NavLink>
        </div>
        <Button
          onClick={() => dispatch(setSideBarShow(false))}
          className="text-3xl bg-gray-100 text-black rounded-full w-[40px] h-[40px] sm:block lg:hidden"
        >
          <i className="fa-solid fa-xmark text-black"></i>
        </Button>
      </div>
      {/* line */}
      <div className="px-3">
        <div className=" border border-gray-500 w-full h-[1px] dark:border-white"></div>
      </div>

      <div className="h-auto">
        {/* items */}
        <div className="sm:grid sm:gap-y-5 sm:mt-3 sm:px-3 sm:text-sm lg:text-base lg:gap-y-3">
          <NavLink
            to="/"
            onClick={() => dispatch(setSideBarShow(false))}
            className={({ isActive }) =>
              isActive
                ? " text-blue-800 font-bold capitalize h-[40px] flex items-center px-3 gap-x-2 cursor-pointer bg-white rounded-lg line-clamp-1"
                : " font-bold capitalize h-[40px] flex items-center px-3 gap-x-2 cursor-pointer border-r-4 border-transparent line-clamp-1"
            }
          >
            <span className="w-[20px] h-full flex items-center justify-center">
              {sideBar_list[0].icon}
            </span>
            <span className="line-clamp-1  flex-1">{sideBar_list[0].name}</span>
          </NavLink>
          <NavLink
            onClick={() => dispatch(setSideBarShow(false))}
            to="/search-page-content"
            className={({ isActive }) =>
              isActive
                ? " text-blue-800 font-bold capitalize h-[40px] flex items-center px-3 gap-x-2 cursor-pointer bg-white rounded-lg line-clamp-1"
                : " font-bold capitalize h-[40px] flex items-center px-3 gap-x-2 cursor-pointer border-r-4 border-transparent line-clamp-1"
            }
          >
            <span className="w-[20px] h-full flex items-center justify-center">
              {sideBar_list[1].icon}
            </span>
            <span className="line-clamp-1  flex-1">{sideBar_list[1].name}</span>
          </NavLink>
          <NavLink
            to="/docs"
            onClick={() => dispatch(setSideBarShow(false))}
            className={({ isActive }) =>
              isActive
                ? " text-blue-800 font-bold capitalize h-[40px] flex items-center px-3 gap-x-2 cursor-pointer bg-white rounded-lg line-clamp-1"
                : " font-bold capitalize h-[40px] flex items-center px-3 gap-x-2 cursor-pointer border-r-4 border-transparent line-clamp-1"
            }
          >
            <span className="w-[20px] h-full flex items-center justify-center">
              {sideBar_list[3].icon}
            </span>
            <span className="line-clamp-1  flex-1">{sideBar_list[3].name}</span>
          </NavLink>
          <NavLink
            to="/music-traditional"
            onClick={() => dispatch(setSideBarShow(false))}
            className={({ isActive }) =>
              isActive
                ? " text-blue-800 font-bold capitalize h-[40px] flex items-center px-3 gap-x-2 cursor-pointer bg-white rounded-lg line-clamp-1"
                : " font-bold capitalize h-[40px] flex items-center px-3 gap-x-2 cursor-pointer border-r-4 border-transparent line-clamp-1"
            }
          >
            <span className="w-[20px] h-full flex items-center justify-center">
              {sideBar_list[9].icon}
            </span>
            <span className="line-clamp-1  flex-1">{sideBar_list[9].name}</span>
          </NavLink>
          <NavLink
            to="/games"
            onClick={() => dispatch(setSideBarShow(false))}
            className={({ isActive }) =>
              isActive
                ? " text-blue-800 font-bold capitalize h-[40px] flex items-center px-3 gap-x-2 cursor-pointer bg-white rounded-lg line-clamp-1"
                : "font-bold capitalize h-[40px] flex items-center px-3 gap-x-2 cursor-pointer border-r-4 border-transparent line-clamp-1"
            }
          >
            <span className="w-[20px] h-full flex items-center justify-center">
              {sideBar_list[8].icon}
            </span>
            <span className="line-clamp-1  flex-1">{sideBar_list[8].name}</span>
          </NavLink>
          {/* line */}
          {isLogin === true && (
            <>
              <div className="px-3">
                <div className=" border border-gray-500 w-full h-[1px] dark:border-white"></div>
              </div>
              <NavLink
                to="/add-document-file"
                onClick={() => dispatch(setSideBarShow(false))}
                className={({ isActive }) =>
                  isActive
                    ? " text-blue-800 font-bold capitalize h-[40px] flex items-center px-3 gap-x-2 cursor-pointer bg-white rounded-lg line-clamp-1"
                    : "font-bold capitalize h-[40px] flex items-center px-3 gap-x-2 cursor-pointer border-r-4 border-transparent line-clamp-1"
                }
              >
                <span className="w-[20px] h-full flex items-center justify-center">
                  {sideBar_list[10].icon}
                </span>
                <span className="line-clamp-1  flex-1">
                  {sideBar_list[10].name}
                </span>
              </NavLink>
              <NavLink
                to="/manage"
                onClick={() => dispatch(setSideBarShow(false))}
                className={({ isActive }) =>
                  isActive
                    ? " text-blue-800 font-bold capitalize h-[40px] flex items-center px-3 gap-x-2 cursor-pointer bg-white rounded-lg line-clamp-1"
                    : " font-bold capitalize h-[40px] flex items-center px-3 gap-x-2 cursor-pointer border-r-4 border-transparent line-clamp-1"
                }
              >
                <span className="w-[20px] h-full flex items-center justify-center">
                  {sideBar_list[5].icon}
                </span>
                <span className="line-clamp-1  flex-1">
                  {sideBar_list[5].name}
                </span>
              </NavLink>
              {/* handle logout */}
              <div
                onClick={handleLogout}
                className="font-bold capitalize h-[40px] flex items-center px-3 gap-x-2 cursor-pointer line-clamp-1"
              >
                <span className="w-[20px] h-full flex items-center justify-center">
                  {sideBar_list[7].icon}
                </span>
                <span className="line-clamp-1  flex-1">
                  {sideBar_list[7].name}
                </span>
              </div>
            </>
          )}
          {/* nút đăng kí đăng nhập */}
          {isLogin !== true && (
            <>
              <Button
                isNavLink={true}
                url="/sign-up-for-new-users"
                onClick={() => dispatch(setSideBarShow(false))}
                className=" font-bold capitalize h-[40px] flex items-center px-3 gap-x-2 cursor-pointer bg-blue-400 rounded-lg"
              >
                <span className="line-clamp-1  flex-1">Đăng Kí</span>
              </Button>
              <Button
                isNavLink={true}
                onClick={() => dispatch(setSideBarShow(false))}
                url="/sign-in-for-users"
                className="font-bold capitalize h-[40px] flex items-center px-3 gap-x-2 cursor-pointer bg-blue-400 rounded-lg"
              >
                <span className="line-clamp-1  flex-1">Đăng Nhập</span>
              </Button>
            </>
          )}
        </div>
        {isLogin === true && (
          <NavLink
            to="/manage/add-posts"
            className="sm:hidden lg:flex  bg-blue-500 w-full absolute bottom-0 font-bold text-white capitalize h-[40px] flex items-center px-3 cursor-pointer line-clamp-1"
          >
            <span className="w-[40px] h-full flex items-center justify-center">
              <i className="fa-regular fa-pen-to-square"></i>
            </span>
            <span className="line-clamp-1  flex-1">Đăng bài</span>
          </NavLink>
        )}
      </div>
    </div>
  );
};

export default SideBar;
