import React, { Suspense, useEffect } from "react";
import { Route, Routes } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import MusicPage from "./pages/MusicPage";
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from "./store/firebaseconfig";
import { collection, getDocs, query, where } from "firebase/firestore";
import { setRoleUser } from "./slice/GlobalSlice";
import AddDocumentFile from "./pages/AddDocumentFile";
import OverviewDocument from "./modules/Manage/OverviewDocument";
import ViewAllDoc from "./pages/ViewAllDoc";
const Main = React.lazy(() => import("./layout/Main"));
const SignUpPage = React.lazy(() => import("./pages/SignUpPage"));
const SignInPage = React.lazy(() => import("./pages/SignInPage"));
const DashBoard = React.lazy(() => import("./layout/DashBoard"));
const AddCategory = React.lazy(() =>
  import("./modules/actions_add_items/AddCategory")
);
const AddPost = React.lazy(() => import("./modules/actions_add_items/AddPost"));
const OverviewCategories = React.lazy(() =>
  import("./modules/Manage/OverviewCategories")
);
const SearchPage = React.lazy(() => import("./pages/SearchPage"));
const Home = React.lazy(() => import("./pages/Home"));
const ErrorPage = React.lazy(() => import("./pages/ErrorPage"));
const UpdateCategory = React.lazy(() =>
  import("./modules/actions_add_items/UpdateCategory")
);
const DetailPage = React.lazy(() => import("./pages/DetailPage"));
const UpdatePost = React.lazy(() =>
  import("./modules/actions_add_items/UpdatePost")
);
const DocsPage = React.lazy(() => import("./pages/DocsPage"));
const OverviewPosts = React.lazy(() =>
  import("./modules/Manage/OverviewPosts")
);
const GamesPage = React.lazy(() => import("./pages/GamesPage"));

const App = () => {
  const { isLogin } = useSelector((state) => state.Login);
  const { isDarkMode, role_user } = useSelector((state) => state.global);
  const dispatch = useDispatch();
  if (localStorage.getItem("dark") === "true") {
    document.body.classList.add("dark:bg-slate-500");
  }
  useEffect(() => {
    if (isDarkMode === true) {
      const htmlElement = document.querySelector("html");
      htmlElement.setAttribute("class", "dark");
    } else {
      const htmlElement = document.querySelector("html");
      htmlElement.removeAttribute("class", "dark");
    }
  }, [isDarkMode]);
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const getData = async () => {
          try {
            const userId = auth?.currentUser?.uid;
            if (userId) {
              const q = query(
                collection(db, "users"),
                where("userId", "==", userId)
              );
              const querySnapshot = await getDocs(q);
              const doc = querySnapshot.docs[0];
              localStorage.setItem("role", doc.data().role);
              dispatch(setRoleUser(doc.data().role));
            }
          } catch (error) {
            console.error("Error fetching user data:", error);
          }
        };
        getData();
      } else {
        localStorage.setItem("role", "");
      }
    });
  }, [dispatch, role_user]);

  return (
    <>
      <Suspense>
        <Routes>
          <Route path="/" element={<Main></Main>}>
            <Route path="/" element={<Home></Home>}></Route>
            <Route
              path="/search-page-content"
              element={<SearchPage></SearchPage>}
            ></Route>
            <Route
              path="/details-news-page/:slug"
              element={<DetailPage></DetailPage>}
            ></Route>
            <Route path="/games" element={<GamesPage></GamesPage>}></Route>
            <Route path="/docs" element={<DocsPage></DocsPage>}></Route>
            <Route path="/docs/:slug" element={<ViewAllDoc></ViewAllDoc>}></Route>
            {isLogin && (
              <>
                <Route
                  path="/add-document-file"
                  element={<AddDocumentFile></AddDocumentFile>}
                ></Route>
                <Route path="/manage" element={<DashBoard></DashBoard>}>
                  <Route
                    path="/manage"
                    element={<OverviewPosts></OverviewPosts>}
                  ></Route>
                  <Route
                    path="/manage/overview-document"
                    element={<OverviewDocument></OverviewDocument>}
                  ></Route>
                  <Route
                    path="/manage/overview-categories"
                    element={<OverviewCategories></OverviewCategories>}
                  ></Route>
                  <Route
                    path="/manage/categories"
                    element={<AddCategory></AddCategory>}
                  ></Route>
                  <Route
                    path="/manage/categories/:slug"
                    element={<UpdateCategory></UpdateCategory>}
                  ></Route>
                  <Route
                    path="/manage/add-posts"
                    element={<AddPost></AddPost>}
                  ></Route>
                  <Route
                    path="/manage/add-posts/:slug"
                    element={<UpdatePost></UpdatePost>}
                  ></Route>
                </Route>
              </>
            )}
          </Route>
          {isLogin === false && (
            <>
              <Route
                path="/sign-up-for-new-users"
                element={<SignUpPage></SignUpPage>}
              ></Route>
              <Route
                path="/sign-in-for-users"
                element={<SignInPage></SignInPage>}
              ></Route>
            </>
          )}
          <Route
            path="/music-traditional"
            element={<MusicPage></MusicPage>}
          ></Route>
          <Route path="*" element={<ErrorPage></ErrorPage>}></Route>
        </Routes>
      </Suspense>
    </>
  );
};

export default App;
