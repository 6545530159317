import React, { useEffect, useRef, useState } from "react";
import HeaderNav from "../components/HeaderNav";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import CardMusic from "../components/card/CardMusic";
import { data_music } from "../data/data_music";
import SideBar from "../modules/SideBar";
import { useSelector } from "react-redux";
// function getCurrentDate() {
//   const currentDate = new Date();
//   const day = currentDate.getDate();
//   const month = currentDate.getMonth() + 1; // Tháng bắt đầu từ 0, cộng thêm 1
//   const year = currentDate.getFullYear();
//   const formattedDate = `${day < 10 ? "0" : ""}${day}-${
//     month < 10 ? "0" : ""
//   }${month}-${year}`;

//   return formattedDate;
// }
// const currentDate = getCurrentDate();

const data_image = [
  {
    url_image: "https://i.ytimg.com/vi/Ug1BNTsOxes/maxresdefault.jpg",
  },
  {
    url_image:
      "https://file3.qdnd.vn/data/images/0/2023/09/20/dinhhung/a4.jpg?dpi=150&quality=100&w=870",
  },
  {
    url_image:
      "https://sovhtt.baria-vungtau.gov.vn/documents/550774/550808/images1689839_bb.jpg?t=1640072137716",
  },
  {
    url_image:
      "https://cucnghethuatbieudien.gov.vn/uploads/images/IMG_0798.JPG",
  },
  {
    url_image:
      "https://ittpa.baria-vungtau.gov.vn/portal/editor/images/11.jfif",
  },
  {
    url_image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTRBXQUuSFrCfMCItWoSj8NtY0vkuF-F99RYdIPaFQhrnFcOrmAkxBFCO5_2KwXt2mhnmU&usqp=CAU",
  },
  {
    url_image:
      "https://vtv1.mediacdn.vn/zoom/640_400/2020/12/31/1-3-1609404139634731395857.jpg",
  },
  {
    url_image:
      "https://cdn.alongwalk.info/info/wp-content/uploads/2022/08/04071730/image-ho-tram-thang-tien-nhac-hoi-edm-hoan-toan-mien-phi-se-dien-ra-vao-tuan-sau-tai-ba-ria-vung-tau-165954705011283.jpg",
  },
  {
    url_image:
      "https://media-cdn-v2.laodong.vn/storage/newsportal/2023/10/5/1250333/Su-Kien-Du-Lich.jpg",
  },
];
function formatTime(seconds) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
  const formattedSeconds =
    remainingSeconds < 10 ? `0${remainingSeconds}` : `${remainingSeconds}`;

  return `${formattedMinutes}:${formattedSeconds}`;
}
// function random music when button random music active
function random_music() {
  const randomIndex = Math.floor(Math.random() * data_music.length);
  const path_music = data_music[randomIndex].path;
  return path_music;
}
const MusicPage = () => {
  const { isShow } = useSelector((state) => state.ShowSideBarSlice);
  const audioRef = useRef(null);
  const [repeatMusic, setRepeatMusic] = useState(false);
  const [playMusic, setPlayMusic] = useState(false);
  const [duration, setDuration] = useState("00:00");
  const [currentTime, setCurrentTime] = useState(0);
  const [randomMusic, setRandomMusic] = useState(false);
  // function when click item play music
  const handleClick = (e) => {
    const audio_music = document.getElementById("audio-control");
    if (audio_music.src === "") {
      audio_music.src = e.target.innerHTML;
    } else if (audio_music.src !== "") {
      audio_music.pause();
      audio_music.src = e.target.innerHTML;
    }
    audio_music.addEventListener("loadedmetadata", function () {
      audio_music.play();
      setPlayMusic(true);
      setDuration(formatTime(audio_music.duration));
    });
  };

  // function handle button when mouse down to play audio
  const handlePlayMusic = (e) => {
    const audio_music = document.getElementById("audio-control");
    if (audio_music.src !== "") {
      setPlayMusic(!playMusic);
    }
  };

  useEffect(() => {
    const audio_music = document.getElementById("audio-control");
    if (audio_music.src !== "") {
      if (!playMusic) audio_music.pause();
      else audio_music.play();
    }
  }, [playMusic]);

  // function update time current music
  const handleTimeUpdate = () => {
    setCurrentTime(audioRef.current.currentTime);
  };

  // function update value music
  const handleSeek = (event) => {
    const seekTime = event.target.value;
    audioRef.current.currentTime = seekTime;
    setCurrentTime(seekTime);
  };

  // function handle when music ended
  const handleEnded = (e) => {
    const audio_music = document.getElementById("audio-control");
    if (!repeatMusic) {
      if (randomMusic) {
        const path = random_music();
        audio_music.src = path;
        audio_music.play();
        console.log(path);
      } else {
        setPlayMusic(false);
        setDuration("00:00");
        setCurrentTime(0);
      }
    } else {
      audio_music.play();
      setCurrentTime(0);
    }
  };

  return (
    <div className="xl:relative grid grid-cols-7 sm:overflow-hidden xl:overflow-visible">
      <SideBar></SideBar>
      <div
        className={`${
          isShow === true ? "sm:hidden md:block " : ""
        } sm:col-span-7 xl:col-span-6 border-black dark:bg-slate-500 transition-all  relative `}
      >
        <HeaderNav></HeaderNav>
        <div className="slide-poster sm:px-2  xl:px-5 gap-5 select-none">
          <Swiper
            slidesPerView={3}
            spaceBetween={30}
            pagination={{
              clickable: true,
            }}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            centeredSlides={false}
            navigation={false}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper"
            breakpoints={{
              320: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
            }}
          >
            {data_image.length > 0 &&
              data_image.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div className="cursor-pointer sm:h-[200px] lg:h-[250px] rounded-md relative overflow-hidden">
                      <img
                        className="block w-full h-full object-cover"
                        src={item.url_image}
                        alt=""
                      />
                      <div className="absolute w-full h-full bg-black opacity-50 top-0 "></div>
                    </div>
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </div>
        <div className="sm:px-2 mb-5 z-0">
          <h1 className="py-5 font-bold text-xl">Âm nhạc truyền thống</h1>
          <div className=" wrapper-list-music grid sm:grid-cols-1  lg:grid-cols-2 gap-3">
            {data_music.map((item, index) => {
              return (
                <CardMusic
                  onClick={handleClick}
                  key={index}
                  url_path={item.path}
                  title={item.name}
                  author={item.author}
                ></CardMusic>
              );
            })}
          </div>
        </div>

        {/* control music */}
        <div className="sm:fixed lg:sticky flex control-music bottom-0 left-0 right-0 p-2 w-full bg-slate-800 min-h-[70px] z-50">
          <div className="flex gap-x-5 sm:hidden xl:block">
            <div className="w-[50px] h-[50px] overflow-hidden rounded-full">
              <img
                className={`w-full h-full object-cover ${
                  playMusic ? "animate-spin-slow" : undefined
                } `}
                src="https://thuamviet.com/photos/BLOG-TUYEN%20DUNG/CAM%20NANG/CN%20tong%20hop/%C3%82m%20nh%E1%BA%A1c%20truy%E1%BB%81n%20th%E1%BB%91ng%20Vi%E1%BB%87t%20nam%20x%C6%B0a%20v%C3%A0%20nay/am%20nhac%20truyen%20thong%20xua%20va%20nay.jpg"
                alt=""
              />
            </div>
          </div>
          <div className="w-[100%] flex flex-col  items-center justify-center">
            <div className="text-white text-sm flex gap-x-10">
              <button
                onClick={() => {
                  if (repeatMusic === false) {
                    setRandomMusic(!randomMusic);
                  }
                }}
                className={`text-base outline-none ${
                  randomMusic ? "text-blue-500" : ""
                }`}
              >
                <i className="fa-solid fa-forward-fast"></i>
              </button>
              <button className="text-base">
                <i className="fa-solid fa-backward-step"></i>
              </button>
              <button
                onClick={handlePlayMusic}
                className="text-lg border-2 w-[40px] h-[40px] rounded-full"
              >
                {playMusic ? (
                  <i class="fa-solid fa-pause"></i>
                ) : (
                  <i className="fa-solid fa-play"></i>
                )}
              </button>
              <button className="text-base">
                <i className="fa-solid fa-forward-step"></i>
              </button>
              <button
                onClick={() => {
                  if (randomMusic === false) setRepeatMusic(!repeatMusic);
                }}
                className={`text-base outline-none ${
                  repeatMusic ? "text-blue-500" : ""
                }`}
              >
                <i className="fa-solid fa-repeat"></i>
              </button>
            </div>
            <div className="select-none w-[80%] flex gap-x-5 text-white font-[450] items-center justify-center">
              <h1>{formatTime(currentTime)}</h1>
              <input
                type="range"
                id="music_section"
                name="music_section"
                min="0"
                max={audioRef.current ? audioRef.current.duration : 0}
                value={currentTime}
                onChange={playMusic ? handleSeek : undefined}
                className="w-full cursor-pointer outline-none h-[2px] appearance-none bg-gray-300 rounded-md opacity-70 transition-opacity duration-200"
              />
              <h1>{duration}</h1>
            </div>
            <audio
              ref={audioRef}
              onTimeUpdate={handleTimeUpdate}
              onEnded={handleEnded}
              id="audio-control"
              controls
              className="absolute top-0 hidden"
            ></audio>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MusicPage;
