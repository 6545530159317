export const data_music = [
  {
    id: 1,
    name: "Bà Rịa - Vũng Tàu đón xuân về",
    path: "/music/brvt_don_xuan_ve.mp3",
    author: "võ hạ trâm",
  },
  {
    id: 2,
    name: "Bà Rịa - Vũng Tàu in dấu một miền thương",
    path: "/music/brvt_in_dau_mot_mien_thuong.mp3",
    author: "nguyễn an đệ",
  },
  {
    id: 3,
    name: "Bà Rịa - Vũng Tàu vương ra biển lớn",
    path: "/music/brvt_vuong_ra_bien_lon.mp3",
    author: "nguyễn phi hùng",
  },
  {
    id: 4,
    name: "Chặn đường vương tới tương lai",
    path: "/music/chan_duong_vuong_toi_tuong_lai.mp3",
    author: "nhóm fm",
  },
  {
    id: 5,
    name: "Chuyện tình trên sông thị vải",
    path: "/music/chuyen_tinh_tren_song_thi_vai.mp3",
    author: "võ hạ trâm",
  },
  {
    id: 6,
    name: "Duyên Vũng Tàu",
    path: "/music/duyen_vung_tau.mp3",
    author: "đông quân - hà văn",
  },
  {
    id: 7,
    name: "Quê hương tôi đẹp mãi những mùa xuân",
    path: "/music/que_huong_toi_dep_mai_nhung_mua_xuan.mp3",
    author: "thế hiên",
  },
  {
    id: 8,
    name: "Thành phố bên bờ biển Đông",
    path: "/music/thanh_pho_ben_bo_bien_dong.mp3",
    author: "hiền thục",
  },
  {
    id: 9,
    name: "Yêu sao Bà Rịa - Vũng Tàu",
    path: "/music/yeu_sao_brvt.mp3",
    author: "hồng dũng - hồng thắm",
  },
  {
    id: 9,
    name: "Yêu Việt Nam",
    path: "/music/yeuvn.mp3",
    author: "hồng dũng - hồng thắm",
  },
  {
    id: 9,
    name: "Ý chí Việt Nam",
    path: "/music/ychivn.mp3",
    author: "hồng dũng - hồng thắm",
  },
  {
    id: 9,
    name: "Xinh tươi Việt Nam",
    path: "/music/xinhtuoi.mp3",
    author: "hồng dũng - hồng thắm",
  },
  {
    id: 9,
    name: "Xin chào Việt Nam",
    path: "/music/xinchaovn.mp3",
    author: "hồng dũng - hồng thắm",
  },
  {
    id: 9,
    name: "Vững tin Việt Nam",
    path: "/music/vungtinvn.mp3",
    author: "hồng dũng - hồng thắm",
  },
  {
    id: 9,
    name: "Việt Nam Việt Nam",
    path: "/music/vnvn.mp3",
    author: "hồng dũng - hồng thắm",
  },
  {
    id: 9,
    name: "Việt Nam tươi đẹp",
    path: "/music/vntuoidep.mp3",
    author: "hồng dũng - hồng thắm",
  },
  {
    id: 9,
    name: "Yêu Việt Nam",
    path: "/music/yeuvn.mp3",
    author: "hồng dũng - hồng thắm",
  },
  {
    id: 9,
    name: "Việt Nam trong tôi la",
    path: "/music/vntrongtoila.mp3",
    author: "hồng dũng - hồng thắm",
  },
  {
    id: 9,
    name: "Việt Nam tôi",
    path: "/music/vntoi.mp3",
    author: "hồng dũng - hồng thắm",
  },
  {
    id: 9,
    name: "Việt Nam quê tôi",
    path: "/music/vnquetoi.mp3",
    author: "hồng dũng - hồng thắm",
  },
  {
    id: 9,
    name: "Việt Nam phong độ đỉnh cao",
    path: "/music/vnphongdodinhcao.mp3",
    author: "hồng dũng - hồng thắm",
  },
  //

  {
    id: 9,
    name: "Việt Nam ơi mùa xuân đến rồi",
    path: "/music/vnoimuaxuandenroi.mp3",
    author: "hồng dũng - hồng thắm",
  },
  {
    id: 9,
    name: "Việt Nam ơi",
    path: "/music/vnoi.mp3",
    author: "hồng dũng - hồng thắm",
  },
  {
    id: 9,
    name: "Việt Nam những chuyến đi",
    path: "/music/vnnhungchuyendi.mp3",
    author: "hồng dũng - hồng thắm",
  },
  {
    id: 9,
    name: "Việt Nam ngày mơi",
    path: "/music/vnngaymoi.mp3",
    author: "hồng dũng - hồng thắm",
  },
  {
    id: 9,
    name: "Việt Nam ngàn dặm",
    path: "/music/vnngandam.mp3",
    author: "hồng dũng - hồng thắm",
  },
  {
    id: 9,
    name: "Việt Nam i love",
    path: "/music/vnilove.mp3",
    author: "hồng dũng - hồng thắm",
  },
  {
    id: 9,
    name: "Việt Nam dấu yêu",
    path: "/music/vndauyeu.mp3",
    author: "hồng dũng - hồng thắm",
  },
  {
    id: 9,
    name: "Việt Nam đất nước tươi đẹp",
    path: "/music/vndatnguoctuoidep.mp3",
    author: "hồng dũng - hồng thắm",
  },
  {
    id: 9,
    name: "Việt Nam cố lên",
    path: "/music/vncolen.mp3",
    author: "hồng dũng - hồng thắm",
  },
  {
    id: 9,
    name: "Việt Nam chiến thắng",
    path: "/music/vnchienthang.mp3",
    author: "hồng dũng - hồng thắm",
  },
  //
  {
    id: 9,
    name: "Vì một Việt Nam",
    path: "/music/vimotvn.mp3",
    author: "hồng dũng - hồng thắm",
  },
  {
    id: 9,
    name: "U23 Việt Nam vô địch",
    path: "/music/u23vnvodich.mp3",
    author: "hồng dũng - hồng thắm",
  },
  {
    id: 9,
    name: "Tuổi trẻ hôm nay",
    path: "/music/tuoitrehomnay.mp3",
    author: "hồng dũng - hồng thắm",
  },
  {
    id: 9,
    name: "Tự hào Việt Nam",
    path: "/music/tuhaovn.mp3",
    author: "hồng dũng - hồng thắm",
  },
  {
    id: 9,
    name: "Trường Sa Hoàng Sa là của Việt Nam",
    path: "/music/truongsahoangsalacuavn.mp3",
    author: "hồng dũng - hồng thắm",
  },
  {
    id: 9,
    name: "Trẻ Việt Nam",
    path: "/music/trevn.mp3",
    author: "hồng dũng - hồng thắm",
  },
  {
    id: 9,
    name: "Tình yêu Việt Nam",
    path: "/music/tinhyeuvietnam.mp3",
    author: "hồng dũng - hồng thắm",
  },
  {
    id: 9,
    name: "Tiến lên Việt Nam ơi",
    path: "/music/tienlenvnoi.mp3",
    author: "hồng dũng - hồng thắm",
  },
  {
    id: 9,
    name: "Tiếng hát giữa rừng bắc pó",
    path: "/music/tienghatgiuarungbacbo.mp3",
    author: "hồng dũng - hồng thắm",
  },
  {
    id: 9,
    name: "Thương qua Việt Nam",
    path: "/music/thuongquavn.mp3",
    author: "hồng dũng - hồng thắm",
  },
  //
  {
    id: 9,
    name: "Thương cả Việt Nam",
    path: "/music/thuongcavn.mp3",
    author: "hồng dũng - hồng thắm",
  },
  {
    id: 9,
    name: "Tết Việt Nam",
    path: "/music/tetvn.mp3",
    author: "hồng dũng - hồng thắm",
  },
  {
    id: 9,
    name: "Tên tôi Việt Nam",
    path: "/music/tentoivn.mp3",
    author: "hồng dũng - hồng thắm",
  },
  {
    id: 9,
    name: "Quê hương Việt Nam",
    path: "/music/quehuongvn.mp3",
    author: "hồng dũng - hồng thắm",
  },
  {
    id: 9,
    name: "Quê hương tôi",
    path: "/music/quehuongtoi.mp3",
    author: "hồng dũng - hồng thắm",
  },
  {
    id: 9,
    name: "Nối vòng tay lớn",
    path: "/music/noivongtaylon.mp3",
    author: "hồng dũng - hồng thắm",
  },
  {
    id: 9,
    name: "Người Việt Nam",
    path: "/music/nguoivn.mp3",
    author: "hồng dũng - hồng thắm",
  },
  {
    id: 9,
    name: "Người là niềm tin tất thắng",
    path: "/music/nguoilaniemtintatthang.mp3",
    author: "hồng dũng - hồng thắm",
  },
  {
    id: 9,
    name: "Nắm lấy vinh quang",
    path: "/music/namlayvinhquang.mp3",
    author: "hồng dũng - hồng thắm",
  },
  {
    id: 9,
    name: "Việt Nam quê tôi",
    path: "/music/lkvnquetoi.mp3",
    author: "hồng dũng - hồng thắm",
  },
  {
    id: 9,
    name: "Hào khí Việt Nam",
    path: "/music/haokhivn.mp3",
    author: "hồng dũng - hồng thắm",
  },
  {
    id: 9,
    name: "Football number one",
    path: "/music/footballnumber1.mp3",
    author: "hồng dũng - hồng thắm",
  },
  {
    id: 9,
    name: "Đường chúng ta đi",
    path: "/music/duongchungtadi.mp3",
    author: "hồng dũng - hồng thắm",
  },
  {
    id: 9,
    name: "Dòng máu lạc hồng",
    path: "/music/dongmaulachong.mp3",
    author: "hồng dũng - hồng thắm",
  },
  {
    id: 9,
    name: "Đây là Việt Nam",
    path: "/music/daylavietnam.mp3",
    author: "hồng dũng - hồng thắm",
  },
  {
    id: 9,
    name: "Dang người tiếng hát mùa xuân",
    path: "/music/dangnguoitienghatmuaxuan.mp3",
    author: "hồng dũng - hồng thắm",
  },
  {
    id: 9,
    name: "Việt nam trong tôi là",
    path: "/music/codosaovang.mp3",
    author: "hồng dũng - hồng thắm",
  },
  {
    id: 9,
    name: "Chúng con canh giấc ngủ cho người",
    path: "/music/chungconcanhgiacnguchonguoi.mp3",
    author: "hồng dũng - hồng thắm",
  },
  {
    id: 9,
    name: "Ca ngợi Hồ Chủ Tịch",
    path: "/music/cangoihochutich.mp3",
    author: "hồng dũng - hồng thắm",
  },
  {
    id: 9,
    name: "Bên lăng Bác Hồ",
    path: "/music/benlangbacho.mp3",
    author: "hồng dũng - hồng thắm",
  },

  {
    id: 9,
    name: "Bến cảng quê tôi",
    path: "/music/bencangquetoi.mp3",
    author: "hồng dũng - hồng thắm",
  },
  {
    id: 9,
    name: "Bay qua Biển Đông",
    path: "/music/bayquabiendong.mp3",
    author: "hồng dũng - hồng thắm",
  },
  {
    id: 9,
    name: "Bản sắc Việt Nam",
    path: "/music/bansacviet.mp3",
    author: "hồng dũng - hồng thắm",
  },
  {
    id: 9,
    name: "Bài ca đất phương Nam",
    path: "/music/baicadatphuongnam.mp3",
    author: "hồng dũng - hồng thắm",
  },
  {
    id: 9,
    name: "Bác Hồ sống mãi với tây nguyên",
    path: "/music/bachosongmaivoitaynguye.mp3",
    author: "hồng dũng - hồng thắm",
  },
];
