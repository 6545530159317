import { createSlice } from "@reduxjs/toolkit";

const GlobalSlice = createSlice({
  name: "global",
  initialState: {
    infor_user: null,
    data: [],
    isDarkMode: JSON.parse(localStorage.getItem("dark")) || false,
    role_user: "user",
    file: "",
    nameFile: "",
  },
  reducers: {
    setInforUser: (state, action) => {
      return {
        ...state,
        infor_user: action.payload,
      };
    },
    setData: (state, action) => {
      return {
        ...state,
        data: action.payload,
      };
    },
    setIsDarkMode: (state, action) => {
      return {
        ...state,
        isDarkMode: action.payload,
      };
    },
    setRoleUser: (state, action) => {
      return {
        ...state,
        role_user: action.payload,
      };
    },
    setFile: (state, action) => {
      return {
        ...state,
        file: action.payload,
      };
    },
    setNameFile: (state, action) => {
      return {
        ...state,
        nameFile: action.payload,
      };
    },
  },
});
export default GlobalSlice.reducer;
export const {
  setInforUser,
  setData,
  setIsDarkMode,
  setRoleUser,
  setFile,
  setNameFile,
} = GlobalSlice.actions;
