import React, { useEffect, useState } from "react";
import TagNamePage from "../../components/card/TagNamePage";
import Remove from "../../components/actions/Remove";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../store/firebaseconfig";
import Download from "../../components/actions/Download";

const OverviewDocument = () => {
  const [data_document, setDataDocument] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "multiplefile"));
        const data = [];
        querySnapshot.forEach((doc) => {
          data.push(doc.data());
        });
        console.log(data);
        setDataDocument(data);
      } catch (error) {
        console.error("Error fetching documents:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <div className="min-h-[70vh] p-5 sm:px-0">
      <div className="h-full  bg-slate-300 sm:px-2 sm:py-5 lg:mx-5 lg:px-5 lg:rounded-md">
        <TagNamePage
          icon={<i className="fa-solid fa-boxes-stacked"></i>}
          title={`tài liệu - ${data_document.length}`}
          desc="Trang quản trị tài liệu"
        ></TagNamePage>
        <div>
          <input
            autoFocus={false}
            type="text"
            className="w-full h-[40px] bg-[#e4e6eb] sm:text-sm
            placeholder:text-black outline-none border-2 border-transparent focus:border-blue-500  rounded-md px-5 focus:shadow-black transition-all shadow-lg"
            placeholder="Search name of categories"
          />
        </div>
        <table className="bg-white w-full border-1 mt-5">
          <tbody>
            <tr className="sm:text-base bg-blue-400 capitalize">
              <th className="w-1/12 sm:hidden lg:table-cell">ID</th>
              <th className="w-1 ">Tên tài liệu</th>
              <th className="w-1/6 ">Ngày tạo</th>
              <th className="w-1/12">Loại file</th>
              <th className="w-1/12 sm:hidden lg:table-cell">Tác giả</th>
              <th className="w-1/12">Công cụ</th>
            </tr>
            {data_document.length > 0 &&
              data_document.map((item, index) => (
                <tr className="sm:text-base font-bold">
                  <td className="sm:hidden lg:table-cell">
                    {item?.docId?.slice(0, 8) + "...."}
                  </td>
                  <td className="capitalize ">
                    <span className="line-clamp-2">{item?.namefile}</span>
                  </td>
                  <td className="capitalize">
                    {" "}
                    {item.timestamp && typeof item.timestamp === "object"
                      ? // Extract seconds and format the date as needed
                        new Date(item.timestamp.seconds * 1000).toLocaleString()
                      : // Handle the case where timestamp is not an object or is undefined
                        "N/A"}
                  </td>
                  <td className="uppercase">{item?.select_type_file}</td>
                  <td className="sm:hidden lg:table-cell">{item.author}</td>
                  <td className="capitalize flex justify-center gap-x-3 border-none">
                    <Download url_download={item.UrlDownload}></Download>
                    <Remove></Remove>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default OverviewDocument;
