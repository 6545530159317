import React from "react";
import Button from "../button/Button";

const Remove = ({ onClick }) => {
  return (
    <Button
      onClick={onClick}
      className="border-2 sm:w-[35px] sm:h-[35px] md:w-[40px] md:h-[40px]  rounded-lg border-gray-600 flex items-center justify-center"
    >
      <i className="fa-solid fa-trash"></i>
    </Button>
  );
};

export default Remove;
