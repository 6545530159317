import React from "react";

const Download = ({ url_download }) => {
  return (
    <a
      href={url_download}
      className="border-2 sm:w-[35px] sm:h-[35px] md:w-[40px] md:h-[40px]  rounded-lg border-gray-600 flex items-center justify-center"
    >
      <i className="fa-solid fa-download"></i>
    </a>
  );
};

export default Download;
