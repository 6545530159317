import React from "react";

const CardSearch = ({
  title,
  link_image,
  author,
  dateUpdate,
  onClick,
  ...props
}) => {
  return (
    <div
      onClick={onClick}
      className="flex bg-[#dcdee2] p-1 gap-x-2 items-center rounded-md"
    >
      <div className="sm:w-[30px] sm:h-[30px]">
        <img
          className="w-full block h-full object-cover rounded-md"
          src={link_image}
          alt=""
        />
      </div>
      <div className="w-[100%] font-bold capitalize line-clamp-2">{title}</div>
    </div>
  );
};

export default CardSearch;
