import React, { useEffect, useState } from "react";
import HeaderNav from "../components/HeaderNav";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../store/firebaseconfig";
import CardDocs from "../components/card/CardDocs";

const ViewAllDoc = () => {
  const [queryFile, setQueryFile] = useState("");
  const [data, setData] = useState([]);
  useEffect(() => {
    async function getData() {
      if (queryFile) {
        const q = query(
          collection(db, "multiplefile"),
          where("select_category_file", "==", queryFile)
        );
        const querySnapshot = await getDocs(q);
        const datafile = [];
        querySnapshot.forEach((doc) => {
          datafile.push(doc.data());
        });
        setData(datafile);
      } else {
        // If queryFile is not defined, get all data
        const querySnapshot = await getDocs(collection(db, "multiplefile"));
        const datafile = [];
        querySnapshot.forEach((doc) => {
          datafile.push(doc.data());
        });
        setData(datafile);
      }
    }

    getData();
  }, [queryFile]);
  return (
    <div className="border-black dark:bg-slate-500 transition-all">
      <HeaderNav></HeaderNav>
      <div class="sm:px-2 flex gap-x-3">
        <div
          onClick={() => setQueryFile("")}
          className={`${
            queryFile === "" ? "bg-blue-300" : "bg-slate-300"
          } px-3 cursor-pointer xl:text-sm sm:text-sm flex items-center justify-center capitalize  h-[40px] mb-5 rounded-lg outline-none`}
        >
          tất cả
        </div>
        <select
          onChange={(e) => setQueryFile(e.target.value)}
          value={queryFile}
          className={`${
            queryFile !== "" ? "bg-blue-300" : "bg-slate-300"
          } cursor-pointer px-3  text-lg xl:text-sm sm:text-sm  capitalize h-[40px] mb-5 rounded-lg outline-none`}
        >
          <option value="" selected disabled>
            Chọn khối học
          </option>
          <option value="11">khối 11</option>
          <option value="10">khối 10</option>
          <option value="8">khối 8</option>
          <option value="7">khối 7</option>
          <option value="6">khối 6</option>
          <option value="5">khối 5</option>
          <option value="4">khối 4</option>
          <option value="3">khối 3</option>
          <option value="2">khối 2</option>
          <option value="1">khối 1</option>
        </select>
      </div>
      <div className="grid sm:grid-cols-1 lg:grid-cols-5 gap-3 sm:pb-5 sm:px-2">
        {data.length === 0 &&
          Array(80)
            .fill("")
            .map((item, index) => (
              <div key={index} className="mb-4">
                <Stack spacing={1}>
                  <Skeleton variant="rounded" height={300} />
                </Stack>
              </div>
            ))}
        {data.length > 0 &&
          data.map((item, index) => (
            <CardDocs
              key={index}
              title_file={item.namefile}
              type={item.select_type_file}
              block={item.select_category_file}
              download={item.UrlDownload}
              id={item.docId}
            ></CardDocs>
          ))}
      </div>
    </div>
  );
};

export default ViewAllDoc;
